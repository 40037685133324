<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="far fa-chart-line"></i>&nbsp;&nbsp;Data Enrichment</h2>
            </div>
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>

        <div class="col-sm-12 col-md-12 col-lg-12">
                    <card>
                        <template slot="header">
                            <h5 class="card-category text-center">&nbsp;</h5>
                            <h3 class="card-title text-center">Clean and Enrich Your Contacts and Leads</h3>
                        </template>
                        <div>
                        <!--UPLOAD-->
                                <form enctype="multipart/form-data">
                                    <!--<h5>Drag & Drop your suppression List (file type should be .csv). Download <a href="#">example file</a></h5>-->
                                    <div class="dropbox">
                                    <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                                        accept=".csv" class="input-file">
                                        <p v-if="isInitial">
                                        Drag your csv file here to upload<br/>or click to browse<br/>
                                        <base-button type="info" round icon  @click="show_helpguide('dataenrichment')">
                                            <i class="fas fa-question"></i>
                                        </base-button>
                                        </p>
                                        <p v-if="isSaving">
                                            Please wait, your file is being processed. This may take up to 2 minutes
                                        </p>
                                        <p v-if="isSuccess">
                                            Process finished successfully, Thank you!
                                        </p>
                                        <p v-if="isFailed">
                                            Whoops that did not work, please check your file for errors and try again
                                        </p>
                                    </div>
                                </form>
                        </div>
                        <template slot="footer">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                    <div class="pt-2" id="leavepage" style="display:none">
                                        <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                                            <label>* If you stay on this page, the results will download automatically when finished. If you leave this page the results will be emailed to you.</label>
                                        </div>
                                    </div>

                                    <div class="pt-2" id="progressmsgshow" style="display:none">
                                        <div class="progress mt-3" style="height: 5px">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%; height: 100%">0%</div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 pt-2" id="progressmsg">
                                            <label>* Please wait while your file uploads. (It might take a couple of minutes, do not leave or close this page until upload finish.)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </card>
                
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12">
                    <card>
                        <template slot="header">
                            <h5 class="card-category text-center">&nbsp;</h5>
                            <h3 class="card-title text-center">Data Enrichment List</h3>
                        </template>
                        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table :data="queriedData" ref="enrichTableData">
                                <el-table-column  min-width="20%"  label="Invoice Number">
                                    <div slot-scope="props" :id="'inv_' + props.row.id">
                                            {{props.row.invoice_number}}
                                    </div>
                                </el-table-column>
                                <el-table-column  min-width="10%" align="center"  label="Total Records">
                                    <div slot-scope="props">
                                            {{props.row.total_row}}
                                    </div>
                                </el-table-column>
                                <el-table-column  min-width="10%" align="center"  label="Cleaned Records">
                                    <div slot-scope="props" :id="'ted_' + props.row.id">
                                            {{props.row.total_row_enrichment}}
                                    </div>
                                </el-table-column>
                                <el-table-column  min-width="10%" align="center"  label="Cost Per Record">
                                    <div slot-scope="props">
                                            ${{props.row.cost_per_lead}}
                                    </div>
                                </el-table-column>
                                 <el-table-column  min-width="10%" align="center"  label="Total Cost">
                                    <div slot-scope="props" :id="'tc_' + props.row.id">
                                            ${{props.row.total_cost}}
                                    </div>
                                </el-table-column>
                                <el-table-column  min-width="10%" align="center"  label="Records in Process">
                                    <div slot-scope="props" :id="'cd_' + props.row.id">
                                            {{props.row.curr_row_process}}
                                    </div>
                                </el-table-column>
                               
                                 <el-table-column  min-width="20%" align="center"  label="Status">
                                    <div slot-scope="props">
                                            <a :id="'fl_' + props.row.id" v-show="props.row.filename != ''" class="pl-2 pr-2" style="color:white" @click="directDownload(props.row.filename)" href="javascript:void(0);" ><i class="fas fa-file-download" style="color:#6699CC"></i></a>
                                            <span :id="'st_' + props.row.id">{{props.row.status}}</span>
                                    </div>
                                </el-table-column>
                            </el-table>
                        </div>

                        <template slot="footer">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 text-center"></div>
                            </div>
                        </template>
                    </card>
        </div>
                    

        <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
            <h4 slot="header" class="title title-up" v-html="modals.helpguideTitle"></h4>
            <p class="text-center" v-html="modals.helpguideTxt">
            </p>
        
            <template slot="footer">
            <div class="container text-center pb-4">
                <base-button  @click.native="modals.helpguide = false">Close</base-button>
            </div>
            </template>
        </modal>
        
    </div>
</template>

<script>
import { Modal } from 'src/components';
import { Table, TableColumn } from 'element-ui';
import axios from 'axios';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
var CHECK_FILE,CHECK_PROGRESS;
axios.defaults.baseURL = process.env.VUE_APP_DATASERVER_URL + '/api';

export default {
    components: {
        Modal,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },

    data() {
        return {
            /** FOR DATA ENRICHMENT */
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'enrichmentfile',
            resultfilename: 'DataEnrichment_',
            /** FOR DATE ENRICHMENT */
            userData: '',

            modals: {
                helpguide: false,
                helpguideTitle:'',
                helpguideTxt: '',
            },

            tableData: [],
            searchedData: [],
            searchQuery: '',
             pagination: {
                perPage: 25,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },

            dataInProgress: [],
            currdomain: '',
        }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      },

      /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
      */
        queriedData() {
        let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        }

    },

    methods: {
        directDownload(fileName) {
            this.$store.dispatch('docDownloadAuth', {
                password: "",
                dataurl: fileName,
            })
            .then(response => {
                if(response.result == "success") {
                    document.location = response.filedoc;
                };
            },error => {
            
            });

        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        updateEnrichmentData() {
            if (this.dataInProgress.length > 0) {
                for(let i=0;i<this.dataInProgress.length;i++) {
                    axios.get('/tools/dataenrichment/data/' + this.userData.id + '/' + this.dataInProgress[i])
                        .then(response => {
                            if(response.data.result == 'success') {
                                $('#ted_' +  this.dataInProgress[i]).html(response.data.datalist[i].total_row_enrichment);
                                $('#tc_' +  this.dataInProgress[i]).html(this.formatPrice(response.data.datalist[i].total_cost));
                                $('#cd_' +  this.dataInProgress[i]).html(response.data.datalist[i].curr_row_process);
                                $('#st_' +  this.dataInProgress[i]).html(response.data.datalist[i].status);
                                $('#inv_' +  this.dataInProgress[i]).html(response.data.datalist[i].invoice_number);

                                if (response.data.datalist[i].filename != '') {
                                    $('#fl_' +  this.dataInProgress[i]).attr('href','javascript:void(0);');
                                    $('#fl_' +  this.dataInProgress[i]).click(directDownload(response.data.datalist[i].filename));
                                    $('#fl_' +  this.dataInProgress[i]).show();
                                }else{
                                    $('#fl_' +  this.dataInProgress[i]).hide();
                                }

                                if (response.data.datalist[i].status == 'completed'){
                                    this.dataInProgress.splice(i,1);
                                }
                            }
                        })
                        .catch(error => {
                        });
                }
            }else{
                 clearInterval(CHECK_PROGRESS);
            }
        },
        getEnrichmentDataList() {
            $('.el-table__empty-text').html('Loading data...');
            this.dataInProgress = [];

            axios.get('/tools/dataenrichment/data/' + this.userData.id)
            .then(response => {
                 if(response.data.result == 'success') {
                    this.tableData = response.data.datalist;
                    
                    if (response.data.datalist.length == 0 ) {
                        $('.el-table__empty-text').html('No Data');
                    }else {
                        
                        for(let i=0;i<response.data.datalist.length;i++) {
                            
                            if (response.data.datalist[i].status == 'progress'){
                                this.dataInProgress.push(response.data.datalist[i].id);
                            }

                            response.data.datalist[i].cost_per_lead = this.formatPrice(response.data.datalist[i].cost_per_lead);
                            response.data.datalist[i].total_cost = this.formatPrice(response.data.datalist[i].total_cost);
                        }

                        if (this.dataInProgress.length > 0) {
                            CHECK_PROGRESS = setInterval(() => {
                                this.updateEnrichmentData();
                            }, 5000);
                        }else{
                            clearInterval(CHECK_PROGRESS);
                        }
                         
                    }
                 }
            })
            .catch(error => {
            });
        },

        showProgress() {
            $('#progressmsgshow .progress').find('.progress-bar').css('width', '0%');
            $('#progressmsgshow .progress').find('.progress-bar').html('0%');
            $('#progressmsgshow .progress').find('.progress-bar').removeClass('bg-success');
            $('#progressmsgshow .progress').show();
            $('#progressmsgshow').show();
        },
        updateProgress(value) {
            $('#progressmsgshow .progress').find('.progress-bar').css('width', `${value}%`)
            $('#progressmsgshow .progress').find('.progress-bar').html(`${value}%`)
        },
        hideProgress() {
            $('#progressmsgshow .progress').hide();
            $('#progressmsgshow').hide();
        },

        /** FOR POP UP GUIDE */
        show_helpguide(helptype) {
            if (helptype == 'dataenrichment') {
                this.modals.helpguideTitle = "START WITH YOUR EXISTING DATA"
                this.modals.helpguideTxt = "To clean and enrich your existing contacts we require at least 2 data points to identify a person (ex. fname, lname, email). The more data points you can provide, including a prior home address, the more accurate our match rate will be. Make sure to use <a href='/samplefile/templateDataEnrichment.csv' target='_blank'>this file template</a>. Max File Size is 3000 Lines"
            }

            this.modals.helpguide = true;
        },
        /** FOR POP UP GUIDE */

        /** FOR UPLOAD FILE */
        checkfileexist() {
            axios.get('/tools/dataenrichment/checkfile/' + this.userData.id + '/' + this.resultfilename)
            .then(response => {
                 if(response.data.result == 'success') {
                    this.currentStatus = STATUS_SUCCESS;

                    clearInterval(CHECK_FILE);

                    setTimeout(() => {
                        this.reset();
                    }, 2000);
                    //window.open(response.data.filename,'_blank');
                    this.directDownload(response.data.filename);
                }
            })
            .catch(error => {
            });
        },
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
            this.uploadFieldName = 'enrichmentfile';
            this.hideProgress();
            $('input[type="file"]').val(null);
            clearInterval(CHECK_FILE);
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;
            this.showProgress();
           //console.log(formData);

           const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin' : '*',
                },
                onUploadProgress: function( progressEvent ) {
                    //DATA TERSEBUT AKAN DI ASSIGN KE VARIABLE progressBar
                    var progressVal = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    this.updateProgress(progressVal);
                    if (progressVal >= 100) {
                        this.hideProgress();
                        $('#leavepage').show();

                        setTimeout(() => {
                            this.getEnrichmentDataList();
                        }, 3000);
                    }
                }.bind(this)
           };
           axios.post('/tools/dataenrichment/upload',formData,config)
            .then(response => {
                console.log(response.data);
                if(response.data.result == 'success') {
                    this.currentStatus = STATUS_SUCCESS;
                    setTimeout(() => {
                        this.reset();
                    }, 2000);
                    //window.open(response.data.filename,'_blank');
                    this.directDownload(response.data.filename);
                }else{
                    this.currentStatus = STATUS_FAILED;
                     setTimeout(() => {
                        this.reset();
                    }, 3000);
                }
            })
            .catch(error => {
                console.log(error);
                //reject(error)
                CHECK_FILE = setInterval(() => {
                    this.checkfileexist();
                }, 5000);
            })
            
        },
        filesChange(fieldName, fileList) {
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
            .from(Array(fileList.length).keys())
            .map(x => {
                formData.append(fieldName, fileList[x], fileList[x].name);
            });

            /** ADD FILENAME */
            var date = new Date();
            this.resultfilename =  'DataEnrichment_' + date.getFullYear() + '' + date.getMonth() + '' + date.getDate() + '' + date.getHours() + date.getMinutes() + date.getSeconds();
            formData.append('resultfilename',this.resultfilename);

            formData.append('useremail',this.userData.email);
            formData.append('companyid',this.userData.company_id);
            formData.append('userid',this.userData.id);
            formData.append('domainName',window.location.hostname);
            // save it
            this.save(formData);
        },
        /** FOR UPLOAD FILE */
    },

    mounted() {
            this.currdomain = window.location.hostname;
            this.userData = this.$store.getters.userData;
            this.reset();
            this.getEnrichmentDataList();
        }
}
</script>


<style>
.select-fullwidth {
    width: 100%;
}

.cardwithminimum {
    min-height: 275px;
}

.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    /* background: #27293d; */
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
   /*  background: #27293d; when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  
  .disabled-area {
    pointer-events:none;
    opacity: 0.3;
  }

  .selectcampaign {
    font-weight:bold;
    color:white;
  }
</style>