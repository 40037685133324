<template>
    <div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <h3 class="info-text">
            <strong>Campaign Ads</strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
        </h3>
        <div class="tab-desc text-center">
            On here you can upload your Campaign Ads Media which is Image or Video<br/>If you have question regarding this <a href="#">click here for help</a>
        </div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 campaign" style="margin: 0px auto; max-width: 1024px;">
                  <tabs
                        type="primary"
                        tabContentClasses="tab-subcategories"
                        square
                        centered
                        class="row"
                    >

                        <tab-pane label="mediaImages">
                            <span slot="label">
                                 <i class="fas fa-images" style="font-size:34px"></i>Image
                            </span>
                            <div class="tab-desc">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        The Display media type allows you to add Image, Native, Advanced HTML, HTML5, and Dynamic creatives to your campaign
                                    </div>
                                </div>
                                <div class="row pt-2 pb-2">&nbsp;</div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-right">
                                        <a href="#" class="btn btn-default"><i class="fas fa-calendar-alt"></i> Images Campaign Date Run</a>
                                    </div>
                                </div>
                                <div class="row pt-2 pb-2">&nbsp;</div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="area">
                                            <div id="dropZone">Drop Image File (ex.jpeg,gif,png) here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab-pane>

                        <tab-pane label="mediaVideos">
                            <span slot="label">
                                 <i class="fas fa-file-video" style="font-size:34px"></i>Video
                            </span>
                            <div class="tab-desc">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        The Video media type allows you to add <strong>Video, 3rd Party Video</strong> and <strong>Companion Ads</strong> creatives to your campaign
                                    </div>
                                </div>
                                <div class="row pt-2 pb-2">&nbsp;</div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-right">
                                        <a href="#" class="btn btn-default"><i class="fas fa-calendar-alt"></i> Animated / VIdeo Campaign Date Run</a>
                                    </div>
                                </div>
                                <div class="row pt-2 pb-2">&nbsp;</div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="area">
                                            <div id="dropZone">Drop Video File here</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </tab-pane>

                  </tabs>
              </div>
        </div>
                    
    </div>
</template>

<script>
import { TabPane, Tabs, Collapse, CollapseItem,BaseProgress } from 'src/components';

export default {
    components: {
        TabPane,
        Tabs,
    },
    data() {
        return {
            
        }
    }
}
</script>

<style>

.area {
  width: 100%;
  padding: 15px;
  border: 1px solid #333;
  background: rgba(0,0,0,0.7);
}
#dropZone {
  border: 2px dashed #bbb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 150px;
  text-align: center;
  font: 21pt bold arial;
  color: #bbb;
}
</style>