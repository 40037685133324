<template>
  <div>
    <span>{{ item.name }}</span>
    <div>
        <small>{{item.description}}</small>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
  }
}
</script>