<template>
    <div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <h3 class="info-text">
            <strong>Campaign Conversions</strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
        </h3>
        <div class="tab-desc text-center">
            Campaign conversions is an action that is counted when someone interacts with your ad (for example, clicks a text ad or views a video ad) and then takes an action that you’ve defined as valuable to your business, such as an online purchase or a call to your business from a mobile phone.<br/>If you have question regarding this <a href="#">click here for help</a>
      </div>
      <div class="row pt-4 pb-4">&nbsp;</div>
       <form @submit.prevent="validate">
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 campaign" style="margin:0 auto;max-width:1224px">
                  <card>
                      
                      <tabs
                        type="primary"
                        tabNavWrapperClasses="col-lg-2 col-md-2"
                        tabContentClasses="col-md-10"
                        vertical
                        square
                        class="row"
                    >
                        <tab-pane>
                            <span slot="label">
                                <i class="fas fa-laptop"></i>Web Site Conversions
                            </span>
                            <div class="row m-0">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    The conversion will be reached when someone clicks to the specific URL that you assign within the audience segment you create below.
                                </div>
                            </div>
                            
                            <div class="row m-0 pt-4 pr-4 pl-2">
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <base-input
                                        label=""
                                        type="text"
                                        placeholder="Search for Audiences"
                                        addon-left-icon="fas fa-search"
                                        >
                                    </base-input>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="form-group ">
                                        
                                        <el-select
                                        class="select-primary"
                                        size="large"
                                        placeholder="Search Organizations"
                                        v-model="selects.simple"
                                        >
                                            <el-option
                                                v-for="option in selects.organizations"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="option.label"
                                                :key="option.label"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>

                            </div>

                            <div class="row m-0 pt-4 pr-4 pl-2">
                                <div class="col-sm-12 col-md-12 col-lg-12 ml-3" style="max-width:960px">
                                    
                                    <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-right pb-4">
                                        <i class="fas fa-plus-circle pr-2"></i><a href="#">Add Audience</a>
                                    </div>

                                    <el-table :data="secondTableData">
                                        <el-table-column width="60" label="#" align="center">
                                        <div slot-scope="{ row }" class="checkbox-cell">
                                            <base-checkbox v-model="row.checked"></base-checkbox>
                                        </div>
                                        </el-table-column>
                                        <el-table-column prop="name" min-width="180" label="Audience Name">
                                        </el-table-column>
                                        <el-table-column prop="users" min-width="60" align="center" label="Users">
                                        </el-table-column>

                                        <el-table-column
                                        prop="status"
                                        min-width="80"
                                        align="center"
                                        label="Status"
                                        >
                                        </el-table-column>

                                        <el-table-column prop="" min-width="80" align="center" label="Action">
                                            <base-button
                                                class="like btn-link"
                                                type="info"
                                                size="sm"
                                                icon
                                              >
                                                <i class="fas fa-check-circle" style="color:green" ></i>
                                              </base-button>
                                              <base-button
                                               
                                                class="like btn-link"
                                                type="info"
                                                size="sm"
                                                icon
                                              >
                                                <i class="far fa-copy"></i>
                                              </base-button>
                                            <base-button
                                                class="edit btn-link"
                                                type="warning"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-pencil"></i>
                                              </base-button>
                                              <base-button
                                                
                                                class="remove btn-link"
                                                type="danger"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-simple-remove"></i>
                                              </base-button>
                                        </el-table-column>
                                        
                                    </el-table>

                                </div>
                                <div class="checkall pb-3">
                                  <img src="/img/arrow_ltr.png" style="float:left;padding-left:50px" /> <a href="javascript:void(0);"  v-on:click.stop.prevent="modals.embededtagcode = true" style="float:left;padding-top:5px;padding-left:20px">Generate Embed Tag Code</a>
                                </div>
                            </div>
                            <hr/>
                            <div class="row m-0 pt-4 pr-4 pl-2">
                                <div class="col-sm-12 col-md-12 col-lg-12 ml-3 text-left" style="max-width:960px">
                                    
                                    <div class="row">
                                            <div class="col-sm-6 col-md-6 col-lg-6">
                                                <h4 class="pt-2 pb-2">CPA Settings</h4>
                                                <div class="row">
                                                    <div class="col-sm-8 col-md-8 col-lg-8">
                                                   
                                                        <slider v-model="sliders.simple" :tooltips="true"> </slider> 
                                                         View Thru Percentage
                                                    </div>
                                                    <div class="col-sm-8 col-md-8 col-lg-8 pt-4">
                                                   
                                                        <slider v-model="sliders.simple" :tooltips="true"> </slider> 
                                                         Click Thru Percentage
                                                    </div>
                                                </div>
                                            
                                            </div>

                                            <div class="col-sm-6 col-md-6 col-lg-6">
                                                <h4 class="pt-2 pb-2">Attribution Settings</h4>
                                                <div class="row">
                                                    <div class="col-sm-8 col-md-8 col-lg-8">
                                                   
                                                        <slider v-model="sliders.simpleday" :tooltips="true" :range="sliderday"> </slider> 
                                                         View Attribution Window by Days
                                                    </div>
                                                    <div class="col-sm-8 col-md-8 col-lg-8 pt-4">
                                                   
                                                        <slider v-model="sliders.simpleday" :tooltips="true" :range="sliderday"> </slider> 
                                                         Click Attribution Window by Days
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </tab-pane>

                        <tab-pane>
                            <span slot="label">
                                <i class="fas fa-street-view"></i>Geo Conversions
                            </span>
                            <div class="row m-0">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                   Geo-fencing allows you to put a digital fence around physical locations so that when a person carrying a device enters within that geo-fence, that device is then sent ads related to that location's products/services, or that location's competitors' products/services.
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-center pt-4 ml-3" style="max-width:960px">
                                   <!--<i class="fas fa-plus-circle pr-2"></i><a href="#">Upload Geo-Fence Finder CSV</a> <i class="fas fa-plus-circle pr-2"></i><a href="#"> Upload GeoJSON</a>-->
                                   <h3>Use the search below to find individual addresses and then draw a fence around the building/parking lot/block.</h3>
                                   
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 ml-3" style="max-width:960px">
                                    <GmapMap
                                        ref="mapRef"
                                        :center="centerMap"
                                        :zoom="16"
                                        map-type-id="roadmap"
                                        style="width: 100%; height: 500px"
                                        :options="{
                                            zoomControl: true,
                                            mapTypeControl: true,
                                            scaleControl: true,
                                            streetViewControl: true,
                                            rotateControl: true,
                                            fullscreenControl: true,
                                            disableDefaultUi: false,
                                            draggable: mapDraggable,
                                            draggableCursor: mapCursor
                                        }"
                                        >

                                        <!--<template #visible>
                                            <gmap-drawing-manager
                                            ref="gdmRef"
                                            :position="toolbarPosition"
                                            :rectangle-options="rectangleOptions"
                                            :polygon-options="polygonOptions"
                                            :circle-options="circleOptions"
                                            :polyline-options="polylineOptions"
                                            :shapes="shapes"
                                            @overlaycomplete="getPositionShape"
                                            />
                                        </template>-->
                                        <template #visible>
                                            <gmap-drawing-manager
                                            ref="gdmRef"
                                            :position="toolbarPosition"
                                            :rectangle-options="rectangleOptions"
                                            :polygon-options="polygonOptions"
                                            :shapes="shapes"
                                            @overlaycomplete="getPositionShape"
                                            />
                                        </template>
                                        
                                        </GmapMap>
                                </div>
                            </div>

                            <div class="row">
                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                     <div class="row pt-4 pr-4 pl-2">
                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                            <!--<base-input
                                                label=""
                                                type="text"
                                                placeholder="Search Location on Map"
                                                addon-left-icon="fas fa-search"
                                                >
                                            </base-input>-->
                                            
                                            <div class="form-group has-icon">
                                                <div class="mb-0 input-group">
                                                    <span class="input-group-prepend">
                                                        <div class="input-group-text">
                                                            <i class="fas fa-search"></i></div></span>
                                                                <gmap-autocomplete2 :value="description"
                                                                    placeholder="Search Location on Map"
                                                                    @place_changed="setPlace"
                                                                    :select-first-on-enter="true"
                                                                    aria-describedby="addon-right addon-left"
                                                                    class="form-control"
                                                                    >
                                                                </gmap-autocomplete2>
                                                            </div>
                                                        </div>
                                        </div>
                                     </div>
                                 </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <el-table :data="secondTableData2">
                                        <el-table-column width="60" label="#" align="center">
                                        <div slot-scope="{ row }" class="checkbox-cell">
                                            <base-checkbox v-model="row.checked"></base-checkbox>
                                        </div>
                                        </el-table-column>
                                        <el-table-column prop="" min-width="180" label="Area Name">
                                            <!--<i class="fas fa-square" style="color:orange"></i> Area Target 1-->
                                            <div slot-scope="{ row }">
                                                 <input type="text" :value="row.name" />&nbsp;&nbsp;<i :class="row.icontype" :style="row.iconcolor"></i>
                                            </div>
                                        </el-table-column>
                                        <el-table-column prop="geotype" min-width="180" align="center" label="GeoType">
                                        </el-table-column>

                                        <el-table-column prop="" min-width="80" align="center" label="Action">
                                            
                                              <base-button
                                                
                                                class="remove btn-link"
                                                type="danger"
                                                size="sm"
                                                icon
                                              >
                                                <i class="tim-icons icon-simple-remove"></i>
                                              </base-button>
                                        </el-table-column>
                                        
                                    </el-table>
                                </div>
                            </div>
                            
                        </tab-pane>

                    </tabs>

                  </card>
              </div>
        </div>
       </form> 
    </div>
</template>

<script>
import { TabPane, Tabs, Slider } from 'src/components';
import { Select, Option, Table, TableColumn } from 'element-ui';

const defaulthome = {lat: 40.05999046180517, lng: -82.72911391121151};
export default {
    components: {
        Slider,
        Tabs,
        TabPane,
        [Option.name]: Option,
        [Select.name]: Select,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    data() {
    return {
        description: '1887 Mink St SW, Pataskala, OH 43062, United States',
        centerMap: defaulthome,
        mapMode: null,
        toolbarPosition: 'TOP_CENTER',
        mapDraggable: true,
        mapCursor: null,
        shapes: [],
          rectangleOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          },
          circleOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          },
          polylineOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          },
          polygonOptions: {
            fillColor: '#777',
            fillOpacity: 0.4,
            strokeWeight: 2,
            strokeColor: '#999',
            draggable: false,
            editable: false,
            clickable: true
          },    

        sliders: {
            simple: 100,
            simpleday:30,
            rangeSlider: [20, 60]
        },
        sliderday: 
            {
                min: 0,
                max: 30
            }
        ,
        selects: {
            simple: '',
            organizations: [
            { value: 'API Development', label: 'API Development' },
            { value: 'Uncommon Reach', label: 'Uncommon Reach' },
            ],
      },
        secondTableData2: [
            
        ],
        secondTableData: [
        {
          id: 1,
          name: 'API Development Main Website Audience',
          organization: 'API Development',
          checked: true,
          users: '109',
          status: 'Active'
        },
        {
          name: 'API Development Main Website Audience Original',
          organization: 'API Development',
          checked: false,
          users: '109',
          status: 'Inactive'
        },
        ],

        latLng: {},

    }
},
 methods: {
     setPlace(place) {
        
        if (!place) return

        this.latLng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        var map =  this.$refs.mapRef

        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        }else{
            map.setCenter(place.geometry.location);
            map.setZoom(21);
        }
    
      },
     getPositionShape(shape) {

      if(shape.type == 'circle') {
        this.$emit('overlaycomplete', shape.overlay.getRadius());
      }else if(shape.type == 'polygon') {
        const vertices = shape.overlay.getPath();
        let contentString = '';
        for (let i = 0; i < vertices.getLength(); i++) {
            const xy = vertices.getAt(i);
            contentString +=
            "<br>" + "Coordinate " + i + ":<br>" + xy.lat() + "," + xy.lng();
        }

        var pk = this.secondTableData2.length

        var newPolygon = {
            id:pk,
            name: 'Polygon ' + pk,
            geotype: 'Conversion',
            icontype: 'tim-icons icon-vector',
            iconcolor: 'color:red',
        }

        this.secondTableData2.push(newPolygon)

        console.log(contentString);
      }else if(shape.type == 'rectangle') {
          const ne = shape.overlay.getBounds().getNorthEast();
          const sw = shape.overlay.getBounds().getSouthWest();

            const contentString =
                "<b>Rectangle moved.</b><br>" +
                "New north-east corner: " +
                ne.lat() +
                ", " +
                ne.lng() +
                "<br>" +
                "New south-west corner: " +
                sw.lat() +
                ", " +
                sw.lng();

        var pk = this.secondTableData2.length

        var newRectangle = {
            id:pk,
            name: 'Rectangle ' + pk,
            geotype: 'Conversion',
            icontype: 'fas fa-square',
            iconcolor: 'color:orange',
        }

        this.secondTableData2.push(newRectangle)

        console.log(contentString);
      }
    },
     
 },
 mounted() {
    this.mapMode = 'ready';
    this.mapDraggable = true;
    this.mapCursor = null;
 },

}
</script>
