<template>
    <div>
        <div class="row">
           <h2>SINGLE BANNER LIST </h2>
        </div>
        <div class="row">

          <div v-if="bannerSingleList.length == 0 && bannerSingleListLoad == false" class="col-sm-12 col-md-12 col-lg-12 text-center">
              <img src="/img/loading2.gif" width="250" />
          </div>

          <div class="col-sm-3 col-md-3 col-lg-3 text-center" v-for="item in bannerSingleList" :key="item.bannerHash">
              <card style="min-height:40vh">
                 <template slot="header">
                   <h4>{{ item.name }}</h4>
                 </template>
                 <div>
                    <img :src="item.image" />
                </div>
                <template slot="footer">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6 text-right">
                        <base-button type="info" round icon>
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 text-left">
                        <base-button type="primary" round icon>
                          <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                    </div>
                  </div>
                </template>
              </card>
          </div>
        
        </div>  

        <hr/>

        <div class="row">
           <h2>BANNER SET LIST </h2>
        </div>
        <div class="row">
          <div v-if="bannerSetList.length == 0 && bannerSetLoad == false" class="col-sm-12 col-md-12 col-lg-12 text-center">
              <img src="/img/loading2.gif" width="250" />
          </div>

          <div class="col-sm-3 col-md-3 col-lg-3 text-center" v-for="item in bannerSetList" :key="item.bannerHash">
              <card>
                 <template slot="header">
                   <h4>{{ item.name }}</h4>
                 </template>
                 <div>
                    <img :src="item.image" />
                </div>
                <template slot="footer">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6 text-right">
                        <base-button type="info" round icon>
                          <i class="tim-icons icon-pencil"></i>
                        </base-button>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 text-left">
                        <base-button type="primary" round icon>
                          <i class="tim-icons icon-trash-simple"></i>
                        </base-button>
                    </div>
                  </div>
                </template>
              </card>
          </div>
        
        </div>  

    </div>  
</template>

<script>

export default {
  data() {
    return {
      bannerSingleList: [],
      bannerSetList: [],
      bannerSingleListLoad: false,
      bannerSetLoad: false,
    };
  },
  methods: {
   

  },
  mounted() {
    
    /** GET BANNER LIST */
    this.$store.dispatch('GetBannerList', {
            bannertype: 'get-user-banners',
            type:'banners',
        }).then(response => {
            this.bannerSingleListLoad = true;
            this.bannerSingleList = response.data;
        },error => {
             this.bannerSingleListLoad = true;   
        });
        
    /** GET BANNER LIST */

    /** GET BANNER SET */
    this.$store.dispatch('GetBannerList', {
            bannertype: 'get-user-banners',
            type:'bannersets',
        }).then(response => {
            this.bannerSetLoad = true;
            this.bannerSetList = response.data;
        },error => {
            this.bannerSetLoad = true;  
        });
        
    /** GET BANNER SET */

    if(this.$store.state.userData.company_logo != null && this.$store.state.userData.company_logo != '') {
      document.getElementById('companylogosidebar').src = this.$store.state.userData.company_logo
    }else{
      document.getElementById('companylogosidebar').src = '/img/logoplaceholder.png'
    }
     
  },

};

</script>