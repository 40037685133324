<template>
  <div class="row container mt-2" >
    <div class="col-sm-10 col-md-10 col-lg-10" >
        <span>{{ item.name }}</span>
        <div>
            <small>Phone: {{ item.phonenumber }}</small>
        </div>
        <div>
            <small>{{item.description}}</small>
        </div>
       
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2 " v-if="item.photo_reference" >
        <img :src="'https://maps.googleapis.com/maps/api/place/photo?maxwidth=200&key=AIzaSyDxcEjhU6zejThHgltOugxqXMObAZq1hM0&photoreference=' + item.photo_reference" class="img-fluid center" @error="errimgsrc(item)" />
    </div>
   <div class="col-sm-12 col-md-12 col-lg-12" ><hr/></div>
  </div>
</template>

<script>
import ImageUpload from '../ImageUpload.vue'
export default {
  components: { ImageUpload },
  props: {
    item: { required: true },
  },
  methods: {
    errimgsrc(item) {
      //console.log(item);
      //event.target.src = "https://user-images.githubusercontent.com/101482/29592647-40da86ca-875a-11e7-8bc3-941700b0a323.png";
      event.target.src = item.photo_reference;
    }
  },
}
</script>