<template>
    <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard @finishstep="finishstep()">
          <template slot="header">
            <h3 class="card-title">Create Your Amazing Campaign!</h3>
             <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="fas fa-bullhorn"></i>
              <p>Campaign Name & Date</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
            ></first-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="fas fa-list-alt"></i>
              <p>Campaign Type</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
            ></second-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="fas fa-university"></i>
              <p>Campaign Budget</p>
            </template>
            <third-step ref="step3"></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step4')">
            <template slot="label">
              <i class="fas fa-desktop"></i>
              <p>Impression Capping</p>
            </template>
            <fourth-step ref="step4"></fourth-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step5')">
            <template slot="label">
              <i class="fas fa-flag-checkered"></i>
              <p>Campaign Conversions</p>
            </template>
            <fifth-step ref="step5"></fifth-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step6')">
            <template slot="label">
              <i class="fas fa-trophy"></i>
              <p>Campaign Goals</p>
            </template>
            <sixth-step ref="step6"></sixth-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step7')">
            <template slot="label">
              <i class="fas fa-bullseye"></i>
              <p>Campaign Target</p>
            </template>
            <seventh-step ref="step7"></seventh-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step8')">
            <template slot="label">
              <i class="fas fa-photo-video"></i>
              <p>Campaign Ads</p>
            </template>
            <eighth-step ref="step8"></eighth-step>
          </wizard-tab>

        </simple-wizard>
      </div>
    </div>
                          <!-- Modal Embeded Tag Code -->
                            <modal :show.sync="modals.embededtagcode" headerClasses="justify-content-center">
                              <h4 slot="header" class="title title-up">Great Job! You are finish setup a Campaign!</h4>
                              <p>
                                Please, put this container code into your website:
                              </p>
                              <div>
                                <textarea cols="61" rows="6">
                                  <script async src='https://tag.exactmatchmarketing.com/167a9430-8af0-0139-b19d-06a60fe5fe77|655f6200-8bd9-0139-b1af-06a60fe5fe77|539e70d0-8c61-0139-8b4d-06b4c2516bae'></script>
                                </textarea>
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="finishsetup">Finish!</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Embeded Tag Code -->        

                            <!-- Modal Video Guide -->
                            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                              <h4 slot="header" class="title title-up">What is on Dashboard Menu?</h4>
                              <p class="text-center">
                                Watch the video below if you still have question please <a href="#">Contact Us</a>
                              </p>
                              <div>
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.helpguide = false">Ok I already understand</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Video Guide -->
  </div>
</template>

<script>

import { SimpleWizard, WizardTab,Modal } from 'src/components';
import FirstStep from './Includes/CampaignSetup/FirstStep.vue';
import SecondStep from './Includes/CampaignSetup/SecondStep.vue';
import ThirdStep from './Includes/CampaignSetup/ThirdStep.vue';
import FourthStep from './Includes/CampaignSetup/FourthStep.vue';
import FifthStep from './Includes/CampaignSetup/FifthStep.vue';
import SixthStep from './Includes/CampaignSetup/SixthStep.vue';
import SeventhStep from './Includes/CampaignSetup/SeventhStep.vue';
import EighthStep from './Includes/CampaignSetup/EighthStep.vue';

export default {
  data() {
    return {
      wizardModel: {},
      modals: {
                embededtagcode: false,
                helpguide:false,
            },
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    SixthStep,
    SeventhStep,
    EighthStep,
    SimpleWizard,
    WizardTab,
    Modal,
  },
  methods: {
    validateStep(ref) {
      console.log(ref);
      return true;
    },
    onStepValidated(validated, model) {
      //console.log(validated.FullName);
      this.wizardModel = { ...this.wizardModel, ...model };
      //console.log(this.wizardModel);
      //console.log(this.wizardModel.FullName);
    },
    finishstep() {
      //swal('Good job!', 'You clicked the finish button!', 'success');
      //alert('God Bless You!');
      this.modals.embededtagcode = true;
    },

    finishsetup() {
      this.$router.push({ name: 'Dashboard' })
    },

  }
};
</script>