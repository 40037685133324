<template>
    <div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <h3 class="info-text">
            <strong>Campaign Type</strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
        </h3>
       
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 campaign" style="margin: 0px auto; max-width: 1024px;">
                  <tabs
                        type="primary"
                        tabContentClasses="tab-subcategories"
                        square
                        centered
                        class="row"
                    >

                        <tab-pane label="SiteRetargeting">
                            <span slot="label">
                                 <i class="fas fa-users" style="font-size:34px"></i>Site Retargeting
                            </span>
                            <div class="tab-desc">
                                <p>Roughly 98% of consumers will leave a site without converting. Here is your chance to re-engage them with a completely new level of performance and insight.<br/>

Site Retargeting targets users who have already visited your site while they are visiting other sites across the web. Site retargeting is a highly effective method of converting site visitors into purchasers.</p>

<p>Exact Match Marketing brings the advantages of element level marketing to site retargeting. While most retargeting companies still operate at the group segment level, <strong>Exact Match Marketing</strong> enables bidding, reporting, and optimizing down to the smallest data elements, such as products, categories, or pages viewed.</p>
 <a href="">Learn more click here.</a>
                            </div>
                        </tab-pane>

                        <tab-pane label="SearchRetargeting">
                            <span slot="label">
                                 <i class="fas fa-search" style="font-size:34px"></i>Search
                            </span>
                            <div class="tab-desc">
                                <p>Reach more searchers, more often, and more effectively with <strong>Exact Match Marketing's</strong> industry leading keyword-level search retargeting. Exact Match Marketing's keyword-level search retargeting enables advertisers to combine the effectiveness of search with the reach and brand impact of display.</p>
<p>Intent data captured while a user is searching is generally the most effective targeting data available. Using search retargeting, advertisers are able to target prospects with display ads based on the searches they perform across the web.</p>
                                <a href="">Learn more click here.</a>
                            </div>
                        </tab-pane>

                        <tab-pane label="Options">
                            <span slot="label">
                                 <i class="fas fa-search-plus" style="font-size:34px"></i>Contextual
                            </span>
                            <div class="tab-desc">
                                <p>Engage new customers by showing them ads that resonate with the content they are already consuming.</p>

<p>Contextual targeting looks at the category or keywords of the current page a consumer is viewing and then serves them ads that are highly relevant to that content. So, viewers only see ads they’re interested in, and you only serve valuable impressions to the right viewers.</p>
 <a href="">Learn more click here.</a>
                            </div>
                        </tab-pane>

                        <tab-pane label="Profile">
                            <span slot="label">
                                 <i class="fas fa-wifi" style="font-size:34px"></i>IP Retargeting
                            </span>
                            <div class="tab-desc">
                                <p>Retargeting is a cookie-based technology that uses simple Javascript code to anonymously 'follow' your audience all over the Web. Here's how it works: you place a small, unobtrusive piece of code on your website (this code is sometimes referred to as a pixel).</p>
                                <a href="">Learn more click here.</a>
                            </div>
                        </tab-pane>

                        <tab-pane label="Profile">
                            <span slot="label">
                                 <i class="fas fa-globe" style="font-size:34px"></i>Geo Optimized
                            </span>
                            <div class="tab-desc">
                                <p>Geo Optimized, otherwise known as local PPC, refers to the practice of delivering different content or advertisements to consumers based on their geographic locations.</p>
                                 <a href="">Learn more click here.</a>
                            </div>
                        </tab-pane>

                  </tabs>
              </div>
        </div>
    </div>
</template>

<script>
import { TabPane, Tabs, Collapse, CollapseItem,BaseProgress } from 'src/components';

export default {
    components: {
        TabPane,
        Tabs,
    }
}
</script>

<style type="text/css">

/*.campaign .nav-pills .nav-link {
    border-radius: 0.25rem;
}
.campaign .nav-pills.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link  {
    margin-right: 10px;
    margin-bottom: 5px;
}

.campaign .nav-pills .nav-item .nav-link:hover {
    background-color: #212529;
}

.campaign .nav-pills .nav-item .nav-link.active, .nav-pills .nav-item .nav-link.active:focus, .nav-pills .nav-item .nav-link.active:hover {
    background-color: #9A9A9A;
    color: #ffffff;
    -webkit-box-shadow: 2px 2px 6px rgb(0 0 0 / 40%);
    box-shadow: 2px 2px 6px rgb(0 0 0 / 40%);
}

.campaign .nav-pills .nav-item {
    min-width: 160px !important;
}

.campaign .nav-pills .nav-item .nav-link {
    padding: 0 15.5px;
    text-align: center;
    padding: 11px 23px;
    min-width: 100px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    background-color: #191f31;
    position: relative;
    height: unset !important;
    width: unset !important;
    top: 0px !important;
    border-radius: 0.25rem !important;
}

.campaign .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #ffffff;
    background-color: #c41a52;
}
.campaign .nav-pills {
    background-color: transparent !important;
    border-radius: unset !important;
    height: unset !important;
}



.campaign .nav-pills.nav-pills-primary .nav-item .nav-link.active, .nav-pills.nav-pills-primary .nav-item .nav-link.active:focus, .nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
    background: #c41a52;
    background-image: -webkit-gradient(linear, right top, left bottom, from(#c41a52), color-stop(#d42e66), to(#c41a52));
    background-image: linear-gradient(to bottom left, #c41a52, #d42e66, #c41a52);
    background-size: 210% 210%;
    background-position: top right;
}
*/
</style>