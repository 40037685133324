<template>
    <div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <h3 class="info-text">
            <strong>Campaign Budget</strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
        </h3>
        <div class="tab-desc text-center">
            Set your budget to run this campaign.<br/>If you have question regarding this <a href="#">click here for help</a>
      </div>
      
      <div class="row pt-4 pb-4">&nbsp;</div>
       <form @submit.prevent="validate">
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin:0 auto;max-width:1024px">
                  <card>
                      <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <!--<div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-input placeholder="Input Your Campaign Name" addon-left-icon="fas fa-bullhorn"> </base-input>
                                </div>
                                
                            </div>-->
                             <base-input
                                label="Campaign Budget"
                                type="text"
                                placeholder="Enter Your Campaign Budget"
                                addon-left-icon="tim-icons icon-money-coins"
                                >
                            </base-input>
                            <small><em>* Campaign budget will run the entire length of dates of the campaign</em></small>
                            <base-input
                                label="Monthly Budget"
                                type="text"
                                placeholder="Enter Your Monthly Budget"
                                addon-left-icon="tim-icons icon-money-coins"
                                class="pt-2"
                                >
                            </base-input>
                            <small><em>* Monthly budget will allocate money towards each calendar month only, so can't be spread between May/June for example.</em></small>
                            <base-input
                                label="Daily Spend Target"
                                type="text"
                                placeholder="Enter Your Daily Spend Target"
                                addon-left-icon="tim-icons icon-money-coins"
                                class="pt-2"
                                >
                            </base-input>
                        
                            <div class="form-group pt-2">
                                <base-checkbox checked>Auto Adjust Daily Spend Target<br/><small>Campaign Budget and End Date Required</small></base-checkbox>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group has-label">
                                <label>Bidding Type</label>
                                <el-select
                                class="select-primary"
                                size="large"
                                placeholder="Enter Bidding Type"
                                v-model="selects.simple"
                                >
                                    <el-option
                                        v-for="option in selects.biddingtype"
                                        class="select-primary"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.label"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                            <base-input
                                label="Bidding Amount"
                                type="text"
                                placeholder="Enter Your Bidding Amount"
                                addon-left-icon="tim-icons icon-money-coins"
                                >
                            </base-input>
                            <div class="pt-2">
                                <small><em><strong>Cost-per-click (CPC)</strong> bidding means that you pay for each click on your ads. For CPC bidding campaigns, you set a maximum cost-per-click bid.</em></small>
                            </div>
                            <div class="pt-2">
                                <small><em><strong>Cost-per-thousand impressions (CPM)</strong> is a way to bid where you pay per one thousand views (impressions).</em></small>
                            </div>
                            <div class="pt-2">
                                <a href="#" style="color:green"><i class="fas fa-calculator" ></i>&nbsp;Calculator to help you plan the budget</a>
                            </div>
                        </div>
                      </div>
                       
                      <div class="row" v-if="budgetplan">
                           <hr/>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <div class="col-sm-6 col-md-6 col-lg-6">
                                    <base-input
                                        label="Daily Impression Cap"
                                        type="text"
                                        placeholder="Enter Your Daily Impression Cap"
                                        addon-left-icon="tim-icons icon-money-coins"
                                        >
                                    </base-input>
                                    <div class="form-group pt-2">
                                        <base-checkbox checked>Auto Adjust Daily Impression Cap<br/><small>Total Impressions Required</small></base-checkbox>
                                    </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12">
                              </div>
                          </div>
                      </div>
                      <div class="stats" slot="footer" >
                          <slot name="footer">
                            <!--<div class="pull-right pr-0"><a href="">Need help <i class="fas fa-question-circle"></i></a></div>-->
                            <div class="pull-right pr-0 text-right">
                                <small>Need Budget Plans?</small><br/>
                                <small><a href="#">Switch to Budget Plans</a></small>
                            </div>
                          </slot>
                      </div>
                  </card>
              </div>
        </div>
       </form>
    </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
components: {
    [Option.name]: Option,
    [Select.name]: Select,
},
data() {
    return {
        selects: {
            simple: '',
            biddingtype: [
            { value: 'CPC Bidding', label: 'CPC Bidding' },
            { value: 'CPM Bidding', label: 'CPM Bidding' },
            ],
      },

      budgetplan: false,
      monthlyplan: true,
    }
},

}
</script>