<template>
    <div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <h3 class="info-text">
            <strong>Impression Capping</strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
        </h3>
        <div class="tab-desc text-center">
            Impression is the number of times a user sees ads in your Display or Video campaign over a given time period. Impression capping works differently on Display campaigns and Video campaigns.<br/>If you have question regarding this <a href="#">click here for help</a>
      </div>
      
      <div class="row pt-4 pb-4">&nbsp;</div>
       <form @submit.prevent="validate">
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin:0 auto;max-width:1024px">
                  <card>
                      <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                            <h4>Impression Capping</h4>
                            <hr/>
                            <!--<div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <base-input placeholder="Input Your Campaign Name" addon-left-icon="fas fa-bullhorn"> </base-input>
                                </div>
                                
                            </div>-->
                             <base-input
                                label="Impressions"
                                type="text"
                                placeholder="Enter Your Total Number of Impressions (All)"
                                addon-left-icon="fas fa-sort-numeric-up-alt"
                                >
                            </base-input>
                            <base-input
                                label="Monthly Impression Capping"
                                type="text"
                                placeholder="Enter Your Total Number of Monthly Impression"
                                 addon-left-icon="fas fa-sort-numeric-up-alt"
                                >
                            </base-input>
                            <base-input
                                label="Daily Impression Capping"
                                type="text"
                                placeholder="Enter Your Total Number of Daily Impression"
                                 addon-left-icon="fas fa-sort-numeric-up-alt"
                                >
                            </base-input>
                            <div class="form-group pt-2">
                                <base-checkbox checked>Auto Adjust Daily Impression Cap</base-checkbox>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                             <h4>Frequency Capping per Hour(s)</h4>
                             <div class="row">
                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                     <base-input
                                        label="Impression Limit Number"
                                        type="text"
                                        placeholder="Total Impression"
                                        addon-left-icon="fas fa-sort-numeric-up-alt"
                                        class="pull-left"
                                        >
                                    </base-input>
                                    <div class="form-group has-label pull-left" style="max-width:120px">
                                        <label>Per Hour(s)</label>
                                        <el-select
                                        class="select-primary"
                                        size="large"
                                        placeholder="Enter Hour"
                                        v-model="selects.simple"
                                        >
                                            <el-option
                                                v-for="option in selects.biddingtype"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="option.label"
                                                :key="option.label"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                 </div>

                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                        <small><em><strong>Impression Capping</strong> applies to per device, Frequency Capping per hour is the most used (ex: 4 impression per 24 hours).</em></small>
                                 </div>
                                 
                             </div>
                            
                            

                        </div>
                      </div>
                       
                      <div class="row" v-if="budgetplan">
                           <hr/>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <div class="col-sm-6 col-md-6 col-lg-6">
                                    <base-input
                                        label="Daily Impression Cap"
                                        type="text"
                                        placeholder="Enter Your Daily Impression Cap"
                                        addon-left-icon="tim-icons icon-money-coins"
                                        >
                                    </base-input>
                                    <div class="form-group pt-2">
                                        <base-checkbox checked>Auto Adjust Daily Impression Cap<br/><small>Total Impressions Required</small></base-checkbox>
                                    </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12">
                              </div>
                          </div>
                      </div>
                      <div class="stats" slot="footer" >
                          <slot name="footer">
                            <!--<div class="pull-right pr-0"><a href="">Need help <i class="fas fa-question-circle"></i></a></div>-->
                            
                          </slot>
                      </div>
                  </card>
              </div>
        </div>
       </form>
    </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
components: {
    [Option.name]: Option,
    [Select.name]: Select,
},
data() {
    return {
        selects: {
            simple: '',
            biddingtype: [
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },

            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' },
            { value: '11', label: '11' },
            { value: '12', label: '12' },

            { value: '13', label: '13' },
            { value: '14', label: '14' },
            { value: '15', label: '15' },
            { value: '16', label: '16' },
            { value: '17', label: '17' },
            { value: '18', label: '18' },

            { value: '19', label: '19' },
            { value: '20', label: '20'},
            { value: '21', label: '21' },
            { value: '22', label: '22' },
            { value: '23', label: '23' },
            { value: '24', label: '24' },
        ],
      },

      budgetplan: false,
      monthlyplan: true,
    }
},

}
</script>