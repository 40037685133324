<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <h2><i class="fas fa-users-cog"></i>&nbsp;&nbsp;Sales Connect Account</h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                <p>To enable menus, please connect your stripe account</p>
            </div>
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        
        <div class="col-sm-12 col-md-12 col-lg-12 text-center">
            <img src="https://emmspaces.nyc3.cdn.digitaloceanspaces.com/systems/banksalesconnect.png" style="color:white" />
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>
        
        <div class="col-sm-12 col-md-12 col-lg-12 text-center" v-if="ActionBtnConnectedAccount == 'createAccount' || ActionBtnConnectedAccount == 'createAccountLink'">
            <base-button :class="statusColorConnectedAccount" :disabled="DisabledBtnConnectedAccount" size="sm" style="height:40px" id="btnCreateConnectedAccount" @click="processConnectedAccount();">
                <i class="fas fa-link"></i> <span v-html="txtStatusConnectedAccount"></span>
            </base-button>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 text-center" v-if="ActionBtnConnectedAccount == 'inverification' || ActionBtnConnectedAccount == 'accountConnected'">
            <h4 :style="{color:statusColorConnectedAccount}" v-html="txtStatusConnectedAccount">&nbsp;</h4>
        </div>

        <div class="pt-3 pb-3">&nbsp;</div>
    </div>
</template>

<script>
import BaseInput from '../../../../components/Inputs/BaseInput.vue';

export default {
    components: {
        BaseInput,
    },
    data() {
        return {
            userData: '',

            txtStatusConnectedAccount: 'Connect your stripe account',
            ActionBtnConnectedAccount: '',
            DisabledBtnConnectedAccount: false,
            statusColorConnectedAccount: '',
            refreshURL: '/configuration/sales-connect-account/',
            returnURL: '/configuration/sales-connect-account/',

            accConID: '',
        }
    },
    methods: {
        createConnectedAccountLink() {
            this.$store.dispatch('createConnectedAccountLink', {
                connectid: this.accConID,
                refreshurl: this.refreshURL,
                returnurl: this.returnURL,
                idsys: this.$global.idsys,
            }).then(response => {
                document.location = response.params.url;
            },error => {
                this.txtStatusConnectedAccount = "Connect your stripe account";
                this.DisabledBtnConnectedAccount = false;
                
                this.$notify({
                    type: 'warning',
                    message: 'we are unable to connect your account, please try again later or contact the support',
                    icon: 'tim-icons icon-bell-55'
                });  
            });
        },
        createConnectedAccount() {
            this.txtStatusConnectedAccount = "Connecting...";
            this.DisabledBtnConnectedAccount = true;

            this.$store.dispatch('createSalesConnectedAccount', {
                usrID: this.userData.id,
                idsys: this.$global.idsys,
            }).then(response => {
                this.accConID = response.params.ConnectAccID;
                this.createConnectedAccountLink();
            },error => {
                this.txtStatusConnectedAccount = "Connect your stripe account";
                this.DisabledBtnConnectedAccount = false;
                this.$notify({
                    type: 'warning',
                    message: 'we are unable to connect your account, with following message: ' + error,
                    icon: 'tim-icons icon-bell-55',
                    duration: 8000,
                });  

            });
        },
        processConnectedAccount() {
            if (this.ActionBtnConnectedAccount == 'createAccount') {
                this.createConnectedAccount();
            }else if (this.ActionBtnConnectedAccount == 'createAccountLink') {
                this.createConnectedAccountLink();
            }
        },
        checkSalesConnectedAccount() {
            this.$store.dispatch('checkSalesConnectedAccount', {
                usrID: this.userData.id,
                idsys: this.$global.idsys,
            }).then(response => {
                //console.log(response.googleSpreadsheetConnected);
                
                if (response.result == 'failed') {
                    this.txtStatusConnectedAccount = "Connect your stripe account";
                    this.ActionBtnConnectedAccount = 'createAccount';
                    this.statusColorConnectedAccount = '';
                }else if (response.result == 'pending') {
                    this.txtStatusConnectedAccount = 'Your stripe registration is incomplete, click here to continue';
                    this.ActionBtnConnectedAccount = 'createAccountLink';
                    this.accConID = response.params[0].acc_connect_id;
                    this.statusColorConnectedAccount = '';
                }else if (response.result == 'pending-verification' || response.result == 'inverification') {
                    this.txtStatusConnectedAccount = 'Almost there, stripe is verifying your account.';
                    this.ActionBtnConnectedAccount = 'inverification';
                    this.accConID = response.params[0].acc_connect_id;
                    this.statusColorConnectedAccount = '#fb6340';
                }else{
                    this.txtStatusConnectedAccount = "Stripe Account Connected"
                    this.ActionBtnConnectedAccount = 'accountConnected';
                    this.statusColorConnectedAccount = '#2dce89';
                }


            },error => {
                
            });
        },
    },
    mounted() {
        this.userData = this.$store.getters.userData;

        /** FOR REFRESH URL CONNECTED STRIPE*/
        if (process.env.VUE_APP_DEVMODE == 'true') {
            // this.refreshURL = 'http://' + window.location.hostname + ':8080' +  this.refreshURL;
            // this.returnURL = 'http://' + window.location.hostname + ':8080' + this.returnURL;
            this.refreshURL = 'https://' + window.location.hostname +  this.refreshURL;
            this.returnURL = 'https://' + window.location.hostname + this.returnURL;
        }else{
            this.refreshURL = 'https://' + window.location.hostname  +  this.refreshURL;
            this.returnURL = 'https://' + window.location.hostname + this.returnURL;
        }
        /** FOR REFRESH URL CONNECTED STRIPE*/
        
        /** CHECK CONNECTED ACCOUNT */
        if (this.userData.user_type == 'sales') {
            this.checkSalesConnectedAccount();
        }
        /** CHECK CONNECTED ACCOUNT */
    }
}
</script>

<style>

</style>
