<template>
    <div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <h3 class="info-text">
            <strong>Campaign Goals</strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
        </h3>
        <!--<div class="tab-desc text-center">
            Set your Campaign Goals there's 4 options : <br/>
            1. <strong>Click-through rate (CTR):</strong> The action of clicking an ad and being taken to another web page via a hyperlink. CTR is calculated by taking the number of clicks your ad has received, divided by the number of times your ad is shown.<br/>
            2. <strong>Cost per acquisition (CPA):</strong> Uses algorithms to optimize for cost per action/acquisition. Should represent the target you wish to pay in order to attain a conversion (i.e., purchase, download, form submit, account sign-up, email sign-up, etc).<br/>
            3. <strong>Cost per click (CPC):</strong> Cost per click means advertisers pay each time a user clicks on the ad. CPC advertising works well when advertisers want visitors to their sites, but it’s a less accurate measurement for advertisers looking to build brand awareness. CPC’s market share has grown each year since its introduction, eclipsing CPM to dominate two-thirds of all online advertising compensation methods.<br/>
            4. <strong>Video Completion Rate (VCR):</strong> is a measurement of the rate at which your digital video impressions play to 100%. Simply put, it’s square one for understanding the efficiency and effectiveness of your video campaign. High VCRs result in more time spent with your message and a lower cost-per-view, while low VCRs indicate room for improvement.
            <br/>If you have question regarding this <a href="#">click here for help</a>
      </div>-->
      <div class="row pt-4 pb-4">&nbsp;</div>
       <form @submit.prevent="validate">
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin:0 auto;max-width:1024px">
                  <card>
                      <div class="row">
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <label class="col-sm-12 col-form-label">Campaign Goals Type:</label>
                                <div class="col-sm-12 checkbox-radios">
                        
                                    <base-radio name="None" v-model="radios.radio1">None</base-radio>
                                    <base-radio name="1" v-model="radios.radio1" selected>CPC (Cost per click)</base-radio>
                                    <base-radio name="CTR" v-model="radios.radio1">CTR (Click Through Rate)</base-radio>
                                    <base-radio name="CPA" v-model="radios.radio1">CPA (Cost per acquisition)</base-radio>
                                    <base-radio name="VCR" v-model="radios.radio1" >VCR (Video Completion Rate)</base-radio>
                                </div>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                            <base-input
                                label="Campaign Goal Value"
                                type="text"
                                placeholder="Enter Your Campaign Goal Value"
                                addon-left-icon="tim-icons icon-money-coins"
                                >
                            </base-input>
                            <label class="col-sm-12 col-form-label pl-0">Auto Optimize</label>
                            <base-switch
                            v-model="switches.defaultOn"
                            type="primary"
                            on-text="ON"
                            off-text="OFF"
                            ></base-switch>
                        </div>
                        <div class="col-sm-4 col-md-4 col-lg-4">
                                <label>What is ... </label>
                                <collapse :multiple-active="false" :active-index="-1">
                                    <collapse-item title="Click-through rate (CTR)">
                                    <div>
                                        The action of clicking an ad and being taken to another web page via a hyperlink. CTR is calculated by taking the number of clicks your ad has received, divided by the number of times your ad is shown.
                                    </div>
                                    </collapse-item>

                                    <collapse-item title="Cost per acquisition (CPA)">
                                    <div>
                                        Uses algorithms to optimize for cost per action/acquisition. Should represent the target you wish to pay in order to attain a conversion (i.e., purchase, download, form submit, account sign-up, email sign-up, etc).
                                    </div>
                                    </collapse-item>

                                    <collapse-item title="Cost per click (CPC)">
                                    <div>
                                        Cost per click means advertisers pay each time a user clicks on the ad. CPC advertising works well when advertisers want visitors to their sites, but it’s a less accurate measurement for advertisers looking to build brand awareness. CPC’s market share has grown each year since its introduction, eclipsing CPM to dominate two-thirds of all online advertising compensation methods.
                                    </div>
                                    </collapse-item>
                                    <collapse-item title="Video Completion Rate (VCR)">
                                    <div>
                                        is a measurement of the rate at which your digital video impressions play to 100%. Simply put, it’s square one for understanding the efficiency and effectiveness of your video campaign. High VCRs result in more time spent with your message and a lower cost-per-view, while low VCRs indicate room for improvement.
                                    </div>
                                    </collapse-item>
                                </collapse>

                        </div>
                      </div>
                  </card>
              </div>
        </div>
       </form>
    </div>
</template>

<script>
import { BaseCheckbox, BaseRadio,BaseSwitch, Collapse, CollapseItem } from 'src/components/index';

export default {
    components: {
    BaseCheckbox,
    BaseRadio,
    BaseSwitch,
    Collapse,
    CollapseItem
  },
  data() {
    return {
      radios: {
        radio1: '1',
        radio2: '2',
        radio3: '2',
        radioOn: '2',
        radioOff: '2'
      },
      switches: {
        defaultOn: true,
        defaultOff: false,
      },  

    }
  }
}
</script>