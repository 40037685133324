<template>
    <div>
        <div class="row pt-4 pb-4">&nbsp;</div>
        <h3 class="info-text">
            <strong>Campaign Target</strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
        </h3>
        <div class="tab-desc text-center">
            Choose your audiences for this campaign, system will targeting audience that you select for your campaign.<br/>You can select multiple audience to target it<br/>If you have question regarding this <a href="#">click here for help</a>
        </div>
        <div class="row pt-4 pb-4">&nbsp;</div>
       <form @submit.prevent="validate">
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12 campaign" style="margin:0 auto;max-width:1224px">
                  <card>
                      
                      <tabs
                        type="primary"
                        tabNavWrapperClasses="col-lg-2 col-md-2"
                        tabContentClasses="col-md-10"
                        vertical
                        square
                        class="row"
                    >
                        <tab-pane>
                            <span slot="label">
                                <i class="fas fa-users"></i>Audiences
                            </span>
                            <div class="row m-0">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    You can create and select your audience for this campaign, you can create your own audience by creating "Web Site Retargeting", "Event Audiences", "Addressable Audience" or also "Opt-out" Audiences.
                                </div>
                            </div>
                            <div class="row m-0 pt-4 pr-4 pl-2">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <collapse :multiple-active="false" :active-index="-1">
                                        <collapse-item title="Site Retargeting (0)">
                                        <div>

                                            <div class="row mt-0">
                                                <div class="col-sm-12 col-md-12 col-lg-12 pb-4">
                                                    <em>Site retargeting is a display advertising technique used by marketers to display advertising to people who have previously visited their website. The marketer includes a pixel within their webpage which sets a cookie in the user's browser. That cookie allows the marketer to target the website visitor with advertising elsewhere on the internet using retargeting.</em>
                                                </div>
                                                <div class="col-sm-6 col-md-6 col-lg-6">
                                                    <base-input
                                                        label=""
                                                        type="text"
                                                        placeholder="Search for Audiences"
                                                        addon-left-icon="fas fa-search"
                                                        >
                                                    </base-input>
                                                </div>
                                                <div class="col-sm-6 col-md-6 col-lg-6">
                                                    <div class="form-group ">
                                                        
                                                        <el-select
                                                        class="select-primary"
                                                        size="large"
                                                        placeholder="Search Organizations"
                                                        v-model="selects.simple"
                                                        >
                                                            <el-option
                                                                v-for="option in selects.organizations"
                                                                class="select-primary"
                                                                :value="option.value"
                                                                :label="option.label"
                                                                :key="option.label"
                                                            >
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row m-0">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 ml-3" style="max-width:960px">
                                                        
                                                        <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-right pb-4">
                                                            <i class="fas fa-plus-circle pr-2"></i><a href="#">Add Audience</a>
                                                        </div>

                                                        <el-table :data="secondTableData">
                                                            <el-table-column width="60" label="#" align="center">
                                                            <div slot-scope="{ row }" class="checkbox-cell">
                                                                <base-checkbox v-model="row.checked"></base-checkbox>
                                                            </div>
                                                            </el-table-column>
                                                            <el-table-column prop="name" min-width="180" label="Audience Name">
                                                            </el-table-column>
                                                            <el-table-column prop="users" min-width="60" align="center" label="Users">
                                                            </el-table-column>

                                                            <el-table-column
                                                            prop="status"
                                                            min-width="80"
                                                            align="center"
                                                            label="Status"
                                                            >
                                                            </el-table-column>

                                                            <el-table-column prop="" min-width="80" align="center" label="Action">
                                                                <base-button
                                                                    class="like btn-link"
                                                                    type="info"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="fas fa-check-circle" style="color:green" ></i>
                                                                </base-button>
                                                                <base-button
                                                                
                                                                    class="like btn-link"
                                                                    type="info"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="far fa-copy"></i>
                                                                </base-button>
                                                                <base-button
                                                                    class="edit btn-link"
                                                                    type="warning"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="tim-icons icon-pencil"></i>
                                                                </base-button>
                                                                <base-button
                                                                    
                                                                    class="remove btn-link"
                                                                    type="danger"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="tim-icons icon-simple-remove"></i>
                                                                </base-button>
                                                            </el-table-column>
                                                            
                                                        </el-table>

                                                    </div>
                                                    <div class="checkall pb-3">
                                                    <img src="/img/arrow_ltr.png" style="float:left;padding-left:50px" /> <a href="javascript:void(0);"  v-on:click.stop.prevent="modals.embededtagcode = true" style="float:left;padding-top:5px;padding-left:20px">Generate Embed Tag Code</a>
                                                    </div>
                                                </div>

                                        </div>
                                        </collapse-item>

                                        <collapse-item title="Event Audiences (0)">
                                        <div>
                                            <!-- START CONTENT -->
                                            <div class="row m-0">
                                                <div class="col-sm-12 col-md-12 col-lg-12 pb-4">
                                                    <em>Allows you to Geo-Fence and target people attending an event, like a concert, trade show, etc. You can capture the devices that are there during the event and then continue to advertise to them afterwards.</em>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <base-input
                                                        label=""
                                                        type="text"
                                                        placeholder="Search for Audiences"
                                                        addon-left-icon="fas fa-search"
                                                        >
                                                    </base-input>
                                                </div>
                                                
                                            </div>

                                            <div class="row m-0">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 ml-3" style="max-width:960px">
                                                        
                                                        <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-right pb-4">
                                                            <i class="fas fa-plus-circle pr-2"></i><a href="#">Setup Event Audience</a>
                                                        </div>

                                                        <el-table :data="secondTableData">
                                                            <el-table-column width="60" label="#" align="center">
                                                            <div slot-scope="{ row }" class="checkbox-cell">
                                                                <base-checkbox v-model="row.checked"></base-checkbox>
                                                            </div>
                                                            </el-table-column>
                                                            <el-table-column prop="name" min-width="180" label="Audience Name">
                                                            </el-table-column>
                                                            <el-table-column prop="users" min-width="60" align="center" label="Users">
                                                            </el-table-column>

                                                            <el-table-column
                                                            prop="status"
                                                            min-width="80"
                                                            align="center"
                                                            label="Status"
                                                            >
                                                            </el-table-column>

                                                            <el-table-column prop="" min-width="80" align="center" label="Action">
                                                                <base-button
                                                                    class="like btn-link"
                                                                    type="info"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="fas fa-check-circle" style="color:green" ></i>
                                                                </base-button>
                                                                <base-button
                                                                
                                                                    class="like btn-link"
                                                                    type="info"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="far fa-copy"></i>
                                                                </base-button>
                                                                <base-button
                                                                    class="edit btn-link"
                                                                    type="warning"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="tim-icons icon-pencil"></i>
                                                                </base-button>
                                                                <base-button
                                                                    
                                                                    class="remove btn-link"
                                                                    type="danger"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="tim-icons icon-simple-remove"></i>
                                                                </base-button>
                                                            </el-table-column>
                                                            
                                                        </el-table>

                                                    </div>
                                                    <div class="checkall pb-3">
                                                    <img src="/img/arrow_ltr.png" style="float:left;padding-left:50px" /> <a href="javascript:void(0);"  v-on:click.stop.prevent="modals.embededtagcode = true" style="float:left;padding-top:5px;padding-left:20px">Generate Embed Tag Code</a>
                                                    </div>
                                                </div>
                                            <!-- START CONTENT -->
                                        </div>
                                        </collapse-item>

                                        <collapse-item title="Addressable Audiences (0)">
                                        <div>
                                            <!-- START CONTENT -->
                                            <div class="row m-0">
                                                <div class="col-sm-12 col-md-12 col-lg-12 pb-4">
                                                    <em>Allows you to narrow down people who fit certain social/purchasing/location demographics.</em>
                                                </div>
                                                <div class="col-sm-6 col-md-6 col-lg-6">
                                                    <base-input
                                                        label=""
                                                        type="text"
                                                        placeholder="Search for Audiences"
                                                        addon-left-icon="fas fa-search"
                                                        >
                                                    </base-input>
                                                </div>
                                                <div class="col-sm-6 col-md-6 col-lg-6">
                                                    <div class="form-group ">
                                                        
                                                        <el-select
                                                        class="select-primary"
                                                        size="large"
                                                        placeholder="Search Organizations"
                                                        v-model="selects.simple"
                                                        >
                                                            <el-option
                                                                v-for="option in selects.organizations"
                                                                class="select-primary"
                                                                :value="option.value"
                                                                :label="option.label"
                                                                :key="option.label"
                                                            >
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row m-0">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 ml-3" style="max-width:960px">
                                                        
                                                        <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-right pb-4">
                                                            <i class="fas fa-plus-circle pr-2"></i><a href="#">Add Audience</a>
                                                        </div>

                                                        <el-table :data="secondTableData">
                                                            <el-table-column width="60" label="#" align="center">
                                                            <div slot-scope="{ row }" class="checkbox-cell">
                                                                <base-checkbox v-model="row.checked"></base-checkbox>
                                                            </div>
                                                            </el-table-column>
                                                            <el-table-column prop="name" min-width="180" label="Audience Name">
                                                            </el-table-column>
                                                            <el-table-column prop="users" min-width="60" align="center" label="Users">
                                                            </el-table-column>

                                                            <el-table-column
                                                            prop="status"
                                                            min-width="80"
                                                            align="center"
                                                            label="Status"
                                                            >
                                                            </el-table-column>

                                                            <el-table-column prop="" min-width="80" align="center" label="Action">
                                                                <base-button
                                                                    class="like btn-link"
                                                                    type="info"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="fas fa-check-circle" style="color:green" ></i>
                                                                </base-button>
                                                                <base-button
                                                                
                                                                    class="like btn-link"
                                                                    type="info"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="far fa-copy"></i>
                                                                </base-button>
                                                                <base-button
                                                                    class="edit btn-link"
                                                                    type="warning"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="tim-icons icon-pencil"></i>
                                                                </base-button>
                                                                <base-button
                                                                    
                                                                    class="remove btn-link"
                                                                    type="danger"
                                                                    size="sm"
                                                                    icon
                                                                >
                                                                    <i class="tim-icons icon-simple-remove"></i>
                                                                </base-button>
                                                            </el-table-column>
                                                            
                                                        </el-table>

                                                    </div>
                                                    <div class="checkall pb-3">
                                                    <img src="/img/arrow_ltr.png" style="float:left;padding-left:50px" /> <a href="javascript:void(0);"  v-on:click.stop.prevent="modals.embededtagcode = true" style="float:left;padding-top:5px;padding-left:20px">Generate Embed Tag Code</a>
                                                    </div>
                                                </div>
                                            <!-- START CONTENT -->
                                        </div>
                                        </collapse-item>
                                        <collapse-item title="Opt-Out Audiences (0)">
                                        <div>
                                            <!-- START CONTENT -->
                                                <div class="row m-0">
                                                    <div class="col-sm-12 col-md-12 col-lg-12 pb-4">
                                                        <em>Exclude the audiences being targeting</em>
                                                    </div>
                                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                                        <base-input
                                                            label=""
                                                            type="text"
                                                            placeholder="Search for Audiences"
                                                            addon-left-icon="fas fa-search"
                                                            >
                                                        </base-input>
                                                    </div>
                                                    
                                                </div>

                                                <div class="row">
                                                        <div class="col-sm-12 col-md-12 col-lg-12 ml-3" style="max-width:960px">
                                                            
                                                            <div class="col-sm-12 col-md-12 col-lg-12 pull-right text-right pb-4">
                                                                <i class="fas fa-plus-circle pr-2"></i><a href="#">Setup Event Audience</a>
                                                            </div>

                                                            <el-table :data="secondTableData">
                                                                <el-table-column width="60" label="#" align="center">
                                                                <div slot-scope="{ row }" class="checkbox-cell">
                                                                    <base-checkbox v-model="row.checked"></base-checkbox>
                                                                </div>
                                                                </el-table-column>
                                                                <el-table-column prop="name" min-width="180" label="Audience Name">
                                                                </el-table-column>
                                                                <el-table-column prop="users" min-width="60" align="center" label="Users">
                                                                </el-table-column>

                                                                <el-table-column
                                                                prop="status"
                                                                min-width="80"
                                                                align="center"
                                                                label="Status"
                                                                >
                                                                </el-table-column>

                                                                <el-table-column prop="" min-width="80" align="center" label="Action">
                                                                    <base-button
                                                                        class="like btn-link"
                                                                        type="info"
                                                                        size="sm"
                                                                        icon
                                                                    >
                                                                        <i class="fas fa-check-circle" style="color:green" ></i>
                                                                    </base-button>
                                                                    <base-button
                                                                    
                                                                        class="like btn-link"
                                                                        type="info"
                                                                        size="sm"
                                                                        icon
                                                                    >
                                                                        <i class="far fa-copy"></i>
                                                                    </base-button>
                                                                    <base-button
                                                                        class="edit btn-link"
                                                                        type="warning"
                                                                        size="sm"
                                                                        icon
                                                                    >
                                                                        <i class="tim-icons icon-pencil"></i>
                                                                    </base-button>
                                                                    <base-button
                                                                        
                                                                        class="remove btn-link"
                                                                        type="danger"
                                                                        size="sm"
                                                                        icon
                                                                    >
                                                                        <i class="tim-icons icon-simple-remove"></i>
                                                                    </base-button>
                                                                </el-table-column>
                                                                
                                                            </el-table>

                                                        </div>
                                                        <div class="checkall pb-3">
                                                        <img src="/img/arrow_ltr.png" style="float:left;padding-left:50px" /> <a href="javascript:void(0);"  v-on:click.stop.prevent="modals.embededtagcode = true" style="float:left;padding-top:5px;padding-left:20px">Generate Embed Tag Code</a>
                                                        </div>
                                                    </div>
                                                <!-- START CONTENT -->
                                        </div>
                                        </collapse-item>
                                    </collapse>
                                </div>
                            </div>
                        </tab-pane>

                        <tab-pane>
                            <span slot="label">
                                <i class="fas fa-map-marker-alt"></i>Locations
                            </span>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                   Coming Soon I still need to think of the layout
                                </div>
                            </div>
                        </tab-pane>

                        <tab-pane>
                            <span slot="label">
                                <i class="fas fa-file-alt"></i>Page Contexts
                            </span>
                            <div class="row pr-4">
                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                    <em>Allows your ads to be included on websites relevant to your business/products/services.</em>
                                 </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    Select the Audience from the Categories below:
                                </div>
                            </div>
                            <div class="row pr-4 pt-4">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                   <div class="block">
                                        <div class="block-inline">
                                            <div class="block-title pull-left"><a href="#">- Arts & Entertainment</a></div>
                                            <div class="block-option pull-right">0 of 7</div>
                                        </div>
                                       <div class="block-content pl-4">
                                            <base-checkbox class="chkflex">Books & Literature</base-checkbox>
                                            <base-checkbox class="chkflex">Celebrity & Gossip</base-checkbox>
                                            <base-checkbox class="chkflex">Fine Art</base-checkbox>
                                            <base-checkbox class="chkflex">Humor</base-checkbox>
                                            <base-checkbox class="chkflex">Movies</base-checkbox>
                                            <base-checkbox class="chkflex">Music</base-checkbox>
                                            <base-checkbox class="chkflex">Television</base-checkbox>
                                            <base-checkbox class="chkflex">Books & Literature</base-checkbox>
                                            <base-checkbox class="chkflex">Celebrity & Gossip</base-checkbox>
                                            <base-checkbox class="chkflex">Fine Art</base-checkbox>
                                            <base-checkbox class="chkflex">Humor</base-checkbox>
                                            <base-checkbox class="chkflex">Movies</base-checkbox>
                                            <base-checkbox class="chkflex">Music</base-checkbox>
                                            <base-checkbox class="chkflex">Television</base-checkbox>
                                        </div>
                                        <hr/>
                                        <div class="block">
                                            <div class="block-title pull-left"><a href="#">+ Automotive</a></div>
                                            <div class="block-option pull-right">0 of 23</div>
                                        </div>
                                         <hr/>
                                        <div class="block">
                                            <div class="block-title pull-left"><a href="#">+ Business</a></div>
                                            <div class="block-option pull-right">0 of 43</div>
                                        </div>
                                         <hr/>
                                        <div class="block">
                                            <div class="block-title pull-left"><a href="#">+ Careers</a></div>
                                            <div class="block-option pull-right">0 of 13</div>
                                        </div>
                                   </div>

                                   
                                </div>
                            </div>
                            
                        </tab-pane>

                      </tabs>
                  </card>

              </div>
        </div>
       </form>
    </div>
</template>

<script>
import { TabPane, Tabs, Collapse, CollapseItem,BaseCheckbox } from 'src/components';
import { Select, Option, Table, TableColumn } from 'element-ui';

export default {
    components: {
        Tabs,
        TabPane,
        Collapse,
        CollapseItem,
        BaseCheckbox,
        [Option.name]: Option,
        [Select.name]: Select,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    data() {
        return {
            selects: {
                simple: '',
                organizations: [
                { value: 'API Development', label: 'API Development' },
                { value: 'Uncommon Reach', label: 'Uncommon Reach' },
                ],
            },    
            secondTableData: [
                {
                id: 1,
                name: 'API Development Main Website Audience',
                organization: 'API Development',
                checked: true,
                users: '109',
                status: 'Active'
                },
                {
                name: 'API Development Main Website Audience Original',
                organization: 'API Development',
                checked: false,
                users: '109',
                status: 'Inactive'
                },
            ],

        }
    },
}
</script>

<style>
.block-content .form-check {
    padding-left:20px !important;
}

.block {
    height: 40px;
    line-height: 35px;
    max-width: 820px;
    margin: 0 auto;
}

.block.block-content {
    padding-left: 20px;
}

.block .block-title {

}

.block .block-option {

}
</style>