<template>

<div class="row">
                         <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table :data="queriedData" ref="InvoicetableData" >
                                    <el-table-column  min-width="20%"  label="Invoice Number">
                                        <div slot-scope="props">
                                              {{props.row.invoice_number}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="10%" align="center"  label="Total Lead(s)">
                                        <div slot-scope="props">
                                              {{props.row.total_leads}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="20%" align="center"  label="Total Cost">
                                        <div slot-scope="props">
                                              $500
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="10%" align="center" label="Status">
                                        <div slot-scope="props">
                                              {{props.row.status}} 
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="20%" align="center"  label="Invoice Date">
                                        <div slot-scope="props">
                                               {{format_date(props.row.invoice_date)}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column min-width="20%" align="center" label="Actions">
                                            <div slot-scope="props">
                                                <base-button
                                                    @click.native="handlePriceSet(props.$index, props.row)"
                                                    class="edit btn-link"
                                                    type="warning"
                                                    size="sm"
                                                    icon
                                                    >
                                                    <i class="fas fa-download" style="color:green"></i>
                                                </base-button>
                                            </div>
                                    </el-table-column>

                            </el-table>
                          
                         </div>

      <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 ">
                   <base-pagination
                      class="pagination-no-border pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="total"
                    >
                    </base-pagination>
                </div>
              </div>
              
      </div>
           

      </div>
  
</template>

<script>

import {Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import moment from 'moment'

export default {
  props: {
    tableData:{},
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'email', 'age'],
      //tableData: [],
      searchedData: [],
      fuseSearch: null,
      
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    format_date(value){
         if (value) {
           return moment(String(value)).format('MM-DD-YYYY')
          }
      },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.1
    });
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  },

};
</script>
<style></style>
