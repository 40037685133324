<template>

<div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 pb-3" id="leadstable">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6" style="opacity:0.8">
                  Total Leads: {{ pagination.total }} entries
                </div>
              </div>     
          </div>
                         <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table :data="queriedData" @sort-change="sortdynamic" ref="LeadtableData" :default-sort="{prop: 'clickdate', order: 'descending'}">
                                    <template #empty v-if="!tableNoData">
                                          <i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...
                                    </template>
                                    <el-table-column prop="full_name" min-width="180" sortable="custom"  label="Full Name">
                                        <div slot-scope="props">
                                              <span v-if="showfirstname">{{props.row.firstname}}</span>  <span v-if="showlastname">{{props.row.lastname}}</span>
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="240" label="Contact">
                                        <div slot-scope="props">
                                              <i class="fas fa-envelope"></i>&nbsp;&nbsp;{{props.row.email}}<br>
                                              <i class="fas fa-phone" v-if="showphone"></i><span v-if="showphone">&nbsp;&nbsp;{{props.row.phone}}</span>
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="280" align="center" label="Address" v-if="showaddress">
                                        <div slot-scope="props">
                                              <span v-if="showaddress">{{props.row.address1}}</span> {{props.row.city}}, {{props.row.state}} {{props.row.zipcode}}
                                        </div>
                                    </el-table-column>
                                    <!--<el-table-column sortable :sort-method="sortcolumn"  min-width="15%" align="center" label="Source">
                                        <div slot-scope="props">
                                              {{props.row.source}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column sortable :sort-method="sortcolumn"  min-width="20%" align="center" label="Referer">
                                        <div slot-scope="props">
                                              {{props.row.referer}}
                                        </div>
                                    </el-table-column>-->
                                    <el-table-column prop="clickdate" sortable :sort-method="sortdate"  min-width="130" align="center" label="Click Date">
                                        <div slot-scope="props">
                                               {{format_date(props.row.clickdate)}}
                                        </div>
                                    </el-table-column>
                            </el-table>
                          
                         </div>

      <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                  Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 ">
                   <base-pagination
                      class="pagination-no-border pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total"
                      @input="changePage"
                      scrollToName="leadstable"
                    >
                    </base-pagination>
                </div>
              </div>
              
      </div>
           

      </div>
  
</template>

<script>

import {Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';
import moment from 'moment'

export default {
  props: {
    tableData:{},
    pagination: {},
    GetLeadsData: {
      type: Function,
    },
    showphone: {
      type: Boolean,
      default: false
    },
    showaddress: {
      type: Boolean,
      default: true
    },
    showfirstname: {
      type: Boolean,
      default: true
    },
    showlastname: {
      type: Boolean,
      default: true
    },
    tableNoData: {
      type: Boolean,
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      searchQuery: '',
      propsToSearch: ['name', 'email', 'age'],
      //tableData: [],
      searchedData: [],
      fuseSearch: null,
      currSortBy: 'clickdate',
      currOrderBy: 'descending'
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    sortcolumn: function(a,b) {
        return a.value - b.value;
    },
    sortdate: function(a,b) {
        return new Date(a.clickdate) - new Date(b.clickdate);
    },
      sortnumber: function(a,b) {
          return a - b;
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('MM-DD-YYYY')
          }
      },
    changePage(event){
        this.GetLeadsData(event, this.currSortBy, this.currOrderBy)
      },
    sortdynamic(column) {
      this.currSortBy = column.prop;
      this.currOrderBy = column.order;
      this.GetLeadsData(this.pagination.currentPage, column.prop, column.order)
      },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.1
    });
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    }
  },

};
</script>
<style></style>
