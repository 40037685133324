<template>
  
      <div class="row justify-content-center centerform">
        <div class="col-sm-12">
          <h2 id="finishheader" class="info-text">Thank you for completing your profile!</h2>
          <h5 id="finishsubheader3" class="info-text">Please click next below to continue</h5>
          <!--<div class="text-center"><iframe width="560" height="315" src="https://www.youtube.com/embed/d3hdMbtuNN0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>-->      
        </div>
      </div>
   
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$refs.form.validate().then(res => {
        if (!res) {
          return;
        }
        this.$emit("on-validated", res);
        return res;
      });
    },
    
  },
  mounted() {
     $('html, body').animate({
          scrollTop: 0
      }, 500);

      const userData = this.$store.getters.userData
      this.$store.dispatch('retrieveUserData',{
                usrID: userData.id,
      }).then(response => {
        if(response == 'success') {
           /** SET TO STORAGE FOR SIDEMENU */
          const updatedData = {
            profile_setup_completed: 'T',
            questionnaire_setup_completed: 'T',
            leadlocalname: this.$global.globalModulNameLink.local.name,
            leadlocalurl: this.$global.globalModulNameLink.local.url,
            leadlocatorname: this.$global.globalModulNameLink.locator.name,
            leadlocatorurl: this.$global.globalModulNameLink.locator.url
          }

          this.$store.dispatch('updateUserData', updatedData);

             /** SET TO STORAGE FOR SIDEMENU */
        }
      });

  }
};
</script>
<style>

@media (max-width: 1024px) {
  .full-width {
    width: 100%;
  }
}

.ccmonth {
  width: 50% !important;
}
.ccyear {
  width: 50% !important;
  padding-left: 10px;
}
</style>
