<template>
    <div>
      <div class="row pt-4 pb-4">&nbsp;</div>
      <h3 class="info-text">
        <strong>Campaign Name & Date </strong>&nbsp;<sup><i class="fas fa-question-circle"></i></sup>
      </h3>
      <div class="tab-desc text-center">
        Set your Campaign Name with enough information for this campaign. you can use this example pattern<br/> "Client Name" - "Ads Type (Image or Video)" - "Something related with campaign" - "campaign run date"
        <br/>Ex. <em>Heartbeats Display Bottles for Life 5/6-6/20/21</em>
      </div>
      <div class="row pt-4 pb-4">&nbsp;</div>
       <form @submit.prevent="validate">
        <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12" style="margin:0 auto;max-width:1024px">
                  <card>
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group has-label pull-left">
                                        <label>Choose Client for Campaign</label>
                                        <el-select
                                        class="select-primary"
                                        size="large"
                                        placeholder="Select Client"
                                        v-model="selects.simple"
                                        >
                                            <el-option
                                                v-for="option in selects.biddingtype"
                                                class="select-primary"
                                                :value="option.value"
                                                :label="option.label"
                                                :key="option.label"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                   
                                      <a href="#" style="position:absolute;margin-top:32px;padding-left:10px">
                                            <i class="fas fa-plus-square"></i> Add Client
                                      </a>
                                   
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-sm-4 col-md-4 col-lg-4">
                            <base-input placeholder="Input Your Campaign Name" addon-left-icon="fas fa-bullhorn"> </base-input>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4">
                            <base-input placeholder="Start Campaign Date">
                                <el-date-picker
                                  type="date"
                                  placeholder="Start Campaign Date"
                                  v-model="datePicker"
                                >
                                </el-date-picker>
                            </base-input>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4">
                              <base-input placeholder="Finish Campaign Date">
                                <el-date-picker
                                  type="date"
                                  placeholder="Finish Campaign Date"
                                  v-model="datePicker"
                                >
                                </el-date-picker>
                            </base-input>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                              <base-checkbox v-model="customid">Custom ID for this campaign (optional)</base-checkbox>
                          </div>

                          <div class="col-sm-4 col-md-4 col-lg-4 pt-3" v-if="customid">
                                <base-input placeholder="Input Your Custom ID for this campaign" addon-left-icon="fas fa-bullhorn"> </base-input>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4">
                            &nbsp;
                          </div>
                      </div>

                      <div class="stats" slot="footer" >
                          <slot name="footer">
                            <div class="pull-right pr-0"><a href="">Need help <i class="fas fa-question-circle"></i></a></div>
                          </slot>
                      </div>
                  </card>
              </div>
        </div>
       </form>
    </div>
</template>

<script>
import { DatePicker, Select, Option} from 'element-ui';
import { BaseCheckbox } from 'src/components/index';
export default {
  components: {
    [DatePicker.name]: DatePicker,
    BaseCheckbox,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      datePicker: '',
      customid: false,
      selects: {
            simple: '',
            biddingtype: [
            { value: 'Kelly Parker', label: 'Kelly Parker' },
            { value: 'Mr. Safety Bar', label: 'Mr. Safety Bar' },
            { value: 'Sam Cooper', label: 'Sam Cooper' },
        ],
      }
    }
  },
}
</script>